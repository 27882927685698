// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: 'Sana All Web Portal',
    appLogoImage: require('@src/assets/images/logo/stadius-beta-logo.png').default,
    appLogoImageSmall: require('@src/assets/images/logo/stadiusv2.png').default,
    sanaAllLogoHori: require('@src/assets/images/logo/sana-all-horizontal.png').default,
    sanaAllLogoVert: require('@src/assets/images/logo/sana-all-vertical.png').default,
    stadiusLogo: require('@src/assets/images/logo/stadius_2.png').default,
    stadiusLogoDark: require('@src/assets/images/logo/stadius-logo-dark.png').default,
    usfLogo: require('@src/assets/images/logo/usf-2022-logo.png').default,
    USFMintMyRideLogo: require('@src/assets/images/logo/USF_Rid3_logo_white.png').default
  },
  layout: {
    isRTL: false,
    skin: 'semi-dark', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'horizontal', // vertical, horizontal
    contentWidth: 'boxed', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig
