// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import ability from './configs/acl/ability'
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { IntlProviderWrapper } from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Flatpickr
import '@styles/react/libs/flatpickr/flatpickr.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Additional styles
import "./assets/themify-icon/themify-icons.css"
import "./assets/simple-line-icon/simple-line-icons.css"
import "./assets/font-awesome/css/all.css"
import "./assets/elagent/style.css"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"
import "./assets/animate.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/main.css"
import "./assets/responsive.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "popper.js"

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import { AbilityContext } from './utility/context/Can'

//** Context Api Providers */

import { DistributionContextProvider, TransactionContextProvider, UserReportContextProvider, SurveyContextProvider } from './context'
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '4452cfb33bde6f08d73a',
  cluster: 'ap1',
  forceTLS: true
})

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <TransactionContextProvider>
            <DistributionContextProvider>
              <UserReportContextProvider>
                <SurveyContextProvider>
                  <IntlProviderWrapper>
                    <LazyApp />
                    <ToastContainer newestOnTop />
                  </IntlProviderWrapper>
                </SurveyContextProvider>
              </UserReportContextProvider>
            </DistributionContextProvider>
          </TransactionContextProvider>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
